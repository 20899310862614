<template>
  <div class="main-content q-pa-md">
    <Mural :count="count" :breadcrumbs="breadcrumbs" route="home" :query="{}">
      <DefaultBtn
        titleButton="Nova Trilha"
        route="new-trail"
        v-show="btnNewTrail"
      />
    </Mural>
    <main class="q-mt-sm">
      <div class="filters">
        <q-btn flat rounded color="default-pink" size="12px" v-if="btnNewTrail">
          <q-img width="20px" :src="filterimg" spinner-color="white" />
          <q-menu>
            <q-list style="min-width: 100px">
              <q-item clickable v-close-popup>
                <q-item-section @click="handleFilterlistUers(COURSE_INACTIVE)">
                  <p>Inativos</p>
                </q-item-section>
              </q-item>
              <q-item
                clickable
                v-close-popup
                @click="handleFilterlistUers(COURSE_ACTIVE)"
              >
                <q-item-section>
                  <p>Ativos</p>
                </q-item-section>
              </q-item>
              <q-item
                clickable
                v-close-popup
                @click="handleFilterlistUers(ALL_COURSES)"
              >
                <q-item-section>
                  <p>Todos</p>
                </q-item-section>
              </q-item>
            </q-list>
          </q-menu>
        </q-btn>
        <div>
          <q-input
            outlined
            rounded
            class="q-pa-sm"
            @keyup="filter"
            dense
            v-model="search"
            placeholder="Pesquisar"
            :rules="[
              (val) =>
                !(val.length < 3) ||
                'Você pode pesquisar os cursos a partir de 3 caracteres!',
            ]"
          >
            <template v-slot:append>
              <q-icon
                name="close"
                class="cursor-pointer"
                v-if="search.length != 0"
                @click="
                  () => {
                    search = '';
                    handleFilterlistUers(filterValue);
                  }
                "
              />
            </template>
            <template v-slot:prepend>
              <q-icon name="search" />
            </template>
          </q-input>
        </div>
      </div>

      <div class="trilha">
        <div class="card-item" :key="course.id" v-for="course in courses">
          <TrilhaInfoCard
            :course="course"
            :image="url_aws_bucket + course.course_image"
            :disabledTrail="disabledTrail"
          />
        </div>
      </div>
      <div class="more-courses" v-if="!visibilityButtonMoreCourses">
        <q-btn
          color="default-pink"
          no-caps
          rounded
          :disable="disableButton"
          label="Carregar mais"
          @click="handleGetCourses"
        />
      </div>
    </main>
    <q-inner-loading :showing="loadingPage">
      <div style="height: auto; width: 70px">
        <LogoAnimated />
      </div>
      <span>Por favor aguarde...</span>
    </q-inner-loading>
    <q-inner-loading :showing="loadingPageForSearching">
      <div style="height: auto; width: 70px">
        <LogoAnimated />
        <span>Por favor aguarde estamos pesquisando a sua busca...</span>
      </div>
    </q-inner-loading>
  </div>
</template>

<script>
//----IMAGES
import filterimg from "@/assets/icons/filter.svg";

//----COMPONENTS
import Mural from "@/components/Mural.vue";
import TrilhaInfoCard from "@/components/courses/cards/TrilhaInfoCard.vue";
import DefaultBtn from "@/components/shared/buttons/DefaultBtn.vue";
import LogoAnimated from "@/components/LoadingIcon.vue";

//----UTILS
import { ENV } from "@/utils/env";
import CanUserAccessClass from "@/utils/CanUserAccessClass";

//----SERVICES
import TrailDataServices from "@/services/TrailDataServices";

//----VUEJS
import { ref, onMounted, computed } from "vue";

import { useQuasar } from "quasar";

export default {
  name: "knowledge",
  components: {
    Mural,
    TrilhaInfoCard,
    DefaultBtn,
    LogoAnimated,
  },
  setup(props) {
    const breadcrumbs = ref(["Trilha"]);
    const url_aws_bucket = ENV.URL_AWS_BUCKET;

    const canUserAccessClass = new CanUserAccessClass();
    const trailDataServices = new TrailDataServices();
    const $q = new useQuasar();
    const COURSE_INACTIVE = 0;
    const COURSE_ACTIVE = 1;
    const ALL_COURSES = 2;

    let courses = ref([]);
    let courses_count = ref("");
    let btnNewTrail = ref(true);
    /*  let filteredCoursed = ref([]); */
    let search = ref("");
    let loadingPage = ref(false);
    let loadingPageForSearching = ref(false);
    let pageNumber = ref(1);
    let pageSize = ref(6);
    let filterValue = ref(1);
    let disableButton = ref(false);
    let visibilityButtonMoreCourses = ref(false);

    function handleGetCourses() {
      if (!disableButton.value) {
        if (filterValue.value === 0) {
          getAllInactive();
        } else if (filterValue.value === 1) {
          getAllActive();
        } else if ((filterValue.value = 2)) {
          getAll();
        }
      }
    }

    function handleNoCoursesToReturn() {
      disableButton.value = true;
      if (courses.value.length != 0) {
        $q.notify({
          type: "warning",
          position: "top",
          message: "Não existem mais cursos para visualizar!",
        });
      } else {
        $q.notify({
          type: "warning",
          position: "top",
          message: "Você ainda não possui nenhum curso!",
        });
      }
    }

    async function getAllActive() {
      loadingPage.value = true;

      await trailDataServices
        .getAllActive(pageNumber.value, pageSize.value)
        .then((response) => {
          if (response.data.data == null) {
            handleNoCoursesToReturn();
            return;
          }
          response.data.data.forEach((course) => {
            courses.value.push(course);
          });

          pageNumber.value = pageNumber.value + 1;
        })
        .catch((error) => {
          console.log(error);
        });

      courses_count.value = courses.value.length;
      loadingPage.value = false;
    }

    async function getAllInactive() {
      loadingPage.value = true;
      await trailDataServices
        .getAllInactive(pageNumber.value, pageSize.value)
        .then((response) => {
          if (response.data.data == null) {
            handleNoCoursesToReturn();
            return;
          }
          response.data.data.forEach((course) => {
            courses.value.push(course);
          });

          pageNumber.value = pageNumber.value + 1;
        })
        .catch((error) => {
          console.log(error);
        });

      courses_count.value = courses.value.length;
      loadingPage.value = false;
    }

    async function getAll() {
      loadingPage.value = true;

      await trailDataServices
        .getAll(pageNumber.value, pageSize.value)
        .then((response) => {
          if (response.data.data == null) {
            handleNoCoursesToReturn();
            return;
          }
          response.data.data.forEach((course) => {
            courses.value.push(course);
          });

          pageNumber.value = pageNumber.value + 1;
        })
        .catch((error) => {
          console.log(error);
        });

      courses_count.value = courses.value.length;
      loadingPage.value = false;
    }

    async function handleFilterlistUers(value) {
      filterValue.value = value;
      disableButton.value = false;
      search.value = "";
      resetDataPage();

      switch (value) {
        case COURSE_INACTIVE:
          await getAllInactive();
          return;

        case COURSE_ACTIVE:
          await getAllActive();
          return;

        case ALL_COURSES:
          getAll();
          return;
        default:
          await getAllActive();
          return;
      }
    }

    async function validateActionsPage() {
      if (!canUserAccessClass.canUserAccess("new-trail", false)) {
        btnNewTrail.value = false;
        return;
      }
    }

    async function disabledTrail(courseId) {
      let data = await trailDataServices.disabledTrail(courseId);
      await getAllActive();
    }

    async function filter() {
      if (search.value.trim().length >= 3) {
        visibilityButtonMoreCourses.value = true;
        if (filterValue.value === COURSE_INACTIVE) {
          loadingPageForSearching.value = true;

          await trailDataServices
            .searchAllInactive(search.value)
            .then((response) => {
              handleDataCourse(response.data);
            })
            .catch((error) => {
              $q.notify({
                type: "negative",
                position: "top",
                message: `Houve um erro ao pesquisar <strong>“${search.value}”</strong>, por favor tente novamente!`,
                html: true,
              });
            });

          loadingPageForSearching.value = false;
          return;
        }

        if (filterValue.value === ALL_COURSES) {
          loadingPageForSearching.value = true;
          await trailDataServices
            .searchtAll(search.value)
            .then((response) => {
              handleDataCourse(response.data);
            })
            .catch((error) => {
              $q.notify({
                type: "negative",
                position: "top",
                message: `Houve um erro ao pesquisar <strong>“${search.value}”</strong>, por favor tente novamente!`,
                html: true,
              });
            });
          loadingPageForSearching.value = false;
          return;
        }

        loadingPageForSearching.value = true;
        await trailDataServices
          .searchAllActive(search.value)
          .then((response) => {
            handleDataCourse(response.data);
          })
          .catch((error) => {
            $q.notify({
              type: "negative",
              position: "top",
              message: `Houve um erro ao pesquisar <strong>“${search.value}”</strong>, por favor tente novamente!`,
              html: true,
            });
          });
        loadingPageForSearching.value = false;
      }

      if (search.value.trim().length === 0) {
        handleFilterlistUers(filterValue.value);
        visibilityButtonMoreCourses.value = false;
      }
    }

    function handleDataCourse(data) {
      if (data.length === 0) return;

      courses.value = [];
      data.forEach((course) => {
        courses.value.push(course);
      });
      courses_count.value = courses.value.length;
    }

    function resetDataPage() {
      pageNumber.value = 1;
      pageSize.value = 6;
      courses.value = [];
      courses_count.value = 0;
    }

    onMounted(async () => {
      await validateActionsPage();
      await getAllActive();
    });

    const count = computed(() => {
      if (!loadingPage.value) {
        if (courses_count.value != 1) {
          if (filterValue.value === COURSE_ACTIVE) {
            return `${courses_count.value} cursos ativos`;
          }
          if (filterValue.value === COURSE_INACTIVE) {
            return `${courses_count.value} cursos inativos`;
          }
          if (filterValue.value === ALL_COURSES) {
            return `${courses_count.value} cursos`;
          }
        } else {
          if (filterValue.value === COURSE_ACTIVE) {
            return `${courses_count.value} cursos ativo`;
          }
          if (filterValue.value === COURSE_INACTIVE) {
            return `${courses_count.value} cursos inativo`;
          }
          if (filterValue.value === ALL_COURSES) {
            return `${courses_count.value} curso`;
          }
        }
      }
    });

    return {
      filterimg,

      breadcrumbs,
      loadingPage,
      loadingPageForSearching,
      courses,
      count,
      btnNewTrail,
      url_aws_bucket,
      COURSE_INACTIVE,
      COURSE_ACTIVE,
      ALL_COURSES,
      search,
      disableButton,
      visibilityButtonMoreCourses,
      handleFilterlistUers,
      validateActionsPage,
      disabledTrail,
      filter,
      handleGetCourses,
      filterValue,
    };
  },
};
</script>

<style lang="scss" scoped>
.main-content {
  width: 100%;
  height: 100%;

  .trilha {
    width: 100%;
    overflow-y: auto;
    height: 86%;
    display: flex;
    flex-direction: column;
  }

  .card-item {
    width: 100%;
  }

  main {
    height: 90%;
  }

  hr {
    width: 100%;
    height: 1px;
    background: #e5e5e5;
    border: none;
    margin-bottom: 0.5rem;
  }

  @media (min-width: 800px) {
    flex-direction: row;
  }
}

.filters {
  display: flex;
  justify-content: flex-end;
  margin-bottom: calc(8px * 3);
}

@media (min-width: 900px) {
  .main-content {
    .trilha {
      flex-direction: row;
      flex-wrap: wrap;
      gap: 8px;
    }

    .card-item {
      width: calc(50% - 8px);
    }
  }
}

.svg {
  font-size: 8rem;
  font-weight: 700;
  max-width: 250px;
}

.wrapper {
  font-size: 1rem;
}
.input {
  width: 50px;
  border: none;
  border-bottom: 1px solid;
  margin: 1em;
  padding: 0.5em;
  font-size: 1rem;
}

.disclaimer {
  font-size: 0.7rem;
}

/* a {
  font-variant: small-caps;
} */

.more-courses {
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  padding-bottom: 5px;
}
</style>
\
